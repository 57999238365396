<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }">
    <v-sheet>
      <h1 class="text-center primary--text">CAMARERO-A</h1>
      <ol>
        <li><b>Descripción del puesto:</b> El documento detalla los riesgos a los que están expuestos los camareros, clasificados como tolerables o moderados. Los riesgos incluyen caídas, golpes, cortes, sobreesfuerzos y exposición a sustancias nocivas.</li>
        <li><b>Caídas:</b> Para prevenir caídas, se recomienda mantener las áreas de trabajo limpias y señalizar los obstáculos. Además, proporcionar calzado con suela antideslizante es crucial.</li>
        <li><b>Caídas de objetos:</b> Los riesgos de caída de objetos se pueden mitigar utilizando carritos para transportar platos y asegurando que las estanterías estén bien sujetas.</li>
        <li><b>Golpes y cortes:</b> Los camareros deben utilizar las herramientas adecuadas y seguir las instrucciones del fabricante. Los cuchillos deben tener mangos antideslizantes y guardarse adecuadamente.</li>
        <li><b>sobreesfuerzos:</b>  Es fundamental realizar la manipulación de cargas de forma adecuada, respetando los límites de peso y permitiendo cambios de postura y descansos durante el trabajo.</li>
        <li><b>Contactos eléctricos:</b> No se deben utilizar equipos eléctricos dañados o mojados. Es necesario realizar un mantenimiento regular para evitar riesgos de electrocución.</li>
        <li><b>Exposición a sustancias nocivas:</b> Los trabajadores deben usar ropa de protección y guantes cuando manejen productos químicos. Es esencial mantener una ventilación adecuada y etiquetar correctamente los productos químicos.</li>
        <li><b>Fatiga y estrés:</b> Para prevenir la fatiga y el estrés, se recomienda distribuir las tareas de forma equitativa, prever el trabajo extra, reforzar los turnos durante picos de afluencia y evitar la prolongación excesiva de la jornada laboral.</li>
      </ol>
      <h1 class="text-center primary--text">DOCUMENTACIÓN FIRMAR CAMAREROS</h1>
      <ol>
        <li><b>Registro de entrega de curso de formación:</b> Este documento certifica que el trabajador de la empresa GRUPO HOSTELERO CINCA MEDIO, S.A. ha recibido un curso de formación de 2 horas sobre trabajos de restauración, en modalidad a distancia, en Monzón (Huesca). La firma del trabajador es necesaria para validar la recepción del curso.</li>
        <li><b>Contenido del curso:</b> El curso incluye una introducción y objetivos, una revisión de la legislación de referencia, y tres módulos principales. El Módulo I cubre condiciones generales, el Módulo II abarca los riesgos del trabajo en restaurantes y sus efectos para la salud, y el Módulo III proporciona medidas preventivas y de control.</li>
        <li><b>Registro de entrega de ficha informativa:</b> En cumplimiento del artículo 18 de la Ley de Prevención de Riesgos Laborales, este documento informa a los trabajadores sobre los riesgos derivados de su puesto de trabajo y las medidas correctoras necesarias. Se entrega información específica para ayudantes de camarero y camareros, incluyendo protocolos de actuación COVID-19 según el RD 65.2023.</li>
        <li><b>Registro de entrega de equipos de protección individual:</b> Este documento confirma que el trabajador ha recibido los equipos de protección necesarios, de acuerdo con el artículo 17 de la Ley de Prevención de Riesgos Laborales. También certifica que el trabajador ha sido informado sobre el uso y mantenimiento de estos equipos, asumiendo la responsabilidad de su correcto uso.</li>
        <li><b>Rechazo o aceptación de reconocimiento médico:</b> BALMAR, como servicio de prevención, ofrece a los trabajadores la opción de aceptar o rechazar la vigilancia periódica de su salud. Se destacan consideraciones importantes, como la protección de la intimidad del trabajador, la proporcionalidad de las pruebas según la gravedad de los riesgos y la no discriminación basada en los resultados de los exámenes médicos.</li>
        <li><b>Autorización de uso de maquinaria:</b> Según el Real Decreto 1215/97, este documento autoriza a los trabajadores formados y capacitados para el uso de maquinaria específica. Incluye la entrega del manual de instrucciones correspondiente y confirma que el trabajador ha recibido una formación adecuada para operar los equipos de trabajo designados.</li>
      </ol>
      <h1 class="text-center primary--text">TRABAJOS DE RESTAURACIÓN - EXAMEN</h1>
      <ol>
        <li><b>Características de suelos:</b> Los suelos en las zonas de cocina o preparación de alimentos deben ser de materiales antideslizantes e impermeables, para evitar la acumulación de suciedad y prevenir caídas.</li>
        <li><b>Características de paredes:</b> Las paredes deben ser de color claro para facilitar la detección de suciedad y manchas, lo que permite una limpieza rápida y efectiva.</li>
        <li><b>Almacenamiento de alimentos:</b> Los alimentos deben almacenarse sobre palets de plástico que los aíslen del suelo al menos 10 cm, garantizando condiciones higiénicas y evitando la contaminación.</li>
        <li><b>Envases de alimentos:</b> Después de ser usados, los envases destinados a contener alimentos no deben reutilizarse para almacenar productos de limpieza, debido a la posibilidad de confusiones y la agresividad de estos productos.</li>
        <li><b>Revisión médica de audiometría:</b> Los empleados expuestos a niveles de ruido entre 85 y 90 dB durante 8 horas al día deben someterse a revisiones médicas de audiometría para prevenir problemas auditivos.</li>
        <li><b>Prevención de lesiones con cuchillos:</b> Para evitar lesiones, se recomienda mantener los cuchillos bien afilados, lo que disminuye la presión necesaria y reduce el riesgo de que la hoja resbale.</li>
        <li><b>Riesgo de quemaduras con freidoras:</b> Se deben evitar las temperaturas excesivas del aceite en las freidoras para prevenir incendios y quemaduras graves.</li>
        <li><b>Control de plagas:</b> Los trabajos de lucha contra plagas en cocinas deben realizarse habitualmente por técnicos especializados autorizados, garantizando un manejo seguro y efectivo de los productos pesticidas.</li>
      </ol>
      <h1 class="text-center primary--text">TRABAJOS DE RESTAURACIÓN - MANUAL</h1>
      <ol>
        <li><b>Introducción y objetivos:</b> Este curso subraya la importancia del sector de la restauración en la generación de empleo, especialmente durante periodos vacacionales. El objetivo principal es fomentar la prevención de riesgos laborales, ofreciendo información sobre los riesgos y medidas de control para mejorar las condiciones de trabajo.</li>
        <li><b>Legislación de referencia:</b> Se cubren varias normativas clave, como la Ley 31/1995 de Prevención de Riesgos Laborales, el Real Decreto 486/1997 sobre seguridad y salud en lugares de trabajo, y el Real Decreto 3484/2000 sobre normas de higiene en la elaboración y comercio de comidas preparadas.</li>
        <li><b>Condiciones generales en restaurantes:</b>  Los suelos deben ser impermeables y antideslizantes, las paredes lisas y de color claro, y debe haber una correcta iluminación y ventilación. Estas características facilitan la limpieza y previenen accidentes.</li>
        <li><b>Almacenamiento de alimentos:</b> Se diferencian los alimentos perecederos, que requieren refrigeración, de los no perecederos, que se almacenan a temperatura ambiente. Las condiciones de almacenamiento incluyen materiales de fácil limpieza y protección contra plagas.</li>
        <li><b>Comedor y barra:</b> La higiene y limpieza son esenciales en las áreas de consumo. Se requieren lavamanos de accionamiento no manual, vitrinas protectoras para alimentos y un mantenimiento constante de la cristalería y vajilla en buen estado.</li>
        <li><b>Servicios higiénicos y vestuarios:</b>  Los servicios deben estar dotados de lavabo no manual, jabón líquido y toallas de un solo uso. Los vestuarios deben estar aislados y disponer de taquillas individuales para el personal.</li>
        <li><b>Almacenamiento de basura:</b> Los establecimientos deben tener contenedores con cierre hermético situados en locales aislados del resto de dependencias, asegurando una adecuada gestión de residuos.</li>
      </ol>
      <div style="text-align: center;">
        <b>Riesgos y medidas preventivas:</b> Se identifican riesgos como quemaduras, caídas, esfuerzos repetitivos y exposición a sustancias nocivas, y se detallan medidas preventivas como el uso de equipos de protección, la formación adecuada y la correcta manipulación de cargas.
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { req } from "@/utils/validations.js";
export default {
  data: () => ({
    folder:'',
    dialog: false,
    resolve: null,
    valid: true,
    reject: null,
    message: null,
    title: null,
    rules: {
      req,
    },
    options: {
      color: 'primary',
      width: 490,
      zIndex: 200
    }
  }),
  methods: {
    open(title, message, options) {
      this.folder = ''
      this.dialog = true
      this.valid = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(this.folder)
      this.dialog = false
    },
  }
}
</script>